const Reward = ({mainImage, theme, isRewardShown}) => {
    if (isRewardShown && mainImage) {
        return (
            <div id="reward-modal">
                <img alt="reward" src={theme + "/" + mainImage}/>
            </div>
        );
    }
}

export default Reward;