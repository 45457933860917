const Picker = ({value, setter, options}) => {
    const items = [];

    for (let option of options) {
        items.push(<li
            key={option}
            className={value === option ? "selected" : ""}
            onClick={() => {
                setter(option);
            }}
        >{option}</li>)
    }

    return (
        <ul className="picker">
            {items}
        </ul>
    );
}

export default Picker;