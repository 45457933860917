import Lives from "./Lives";

const Game = (props) => {
    const {lives, factor1, operator, factor2, answerClass, answer, checkSum, countCorrect, countdownStart,
        secondsToAnswer, getCorrect, reset, isMobile
    } = props;

    const press = (key) => {
        const event = new KeyboardEvent('keydown', { key: key.toString() });
        document.dispatchEvent(event);
    }

    if (lives) {
        if (isMobile) {
            return (
                <div className="game">
                    <div id="question">{factor1}{operator}{factor2}</div>
                    <div id="answer" className={answerClass}>{answer}</div>
                    <div
                        className={`depletion ${countdownStart ? 'depletion-start' : ''}`}
                        style={{'--speed': secondsToAnswer + 's'}}
                    >
                        <div className="depletion-bar"></div>
                    </div>
                    <div className="number-pad">
                        <div className="enter" onClick={() => press('Enter')}>&#10141;</div>
                        <div className="back" onClick={() => press('Backspace')}>&lt;</div>
                        <div className="z" onClick={() => press(0)}>0</div>
                        <div className="one" onClick={() => press(1)}>1</div>
                        <div className="two" onClick={() => press(2)}>2</div>
                        <div className="three" onClick={() => press(3)}>3</div>
                        <div className="four" onClick={() => press(4)}>4</div>
                        <div className="five" onClick={() => press(5)}>5</div>
                        <div className="six" onClick={() => press(6)}>6</div>
                        <div className="seven" onClick={() => press(7)}>7</div>
                        <div className="eight" onClick={() => press(8)}>8</div>
                        <div className="nine" onClick={() => press(9)}>9</div>
                    </div>
                </div>
            )
        }

        return (
            <div className="game">
                <div id="question">{factor1}{operator}{factor2}</div>
                <div id="answer" className={answerClass}>{answer}</div>
                <div
                    className={`depletion ${countdownStart ? 'depletion-start' : ''}`}
                    style={{'--speed': secondsToAnswer + 's'}}
                >
                    <div className="depletion-bar"></div>
                </div>
                <button onClick={() => checkSum()}>Volgende <span className="icon fa-solid fa-arrow-right-long"/></button>
                <div id="points">Punten: <span id="correct">{countCorrect}</span></div>
                <Lives lives={lives}/>
            </div>
        )
    }

    let sumFullCls = '';
    if (operator === '+') {
        sumFullCls = 'addition';
    } else if (operator === '-') {
        sumFullCls = 'subtraction';
    }

    return (
        <div className="game">
            <img alt="sad" className="sad" src="images/sad.png"/>
            <div className={`sum-full ${sumFullCls}`}>
                <div className="factor1">{factor1}</div>
                <div className="factor2">{factor2}</div>
                <div className="sum-answer">{getCorrect()}</div>
            </div>
            <button className="again" onClick={() => reset()}>Jammer. Nog een keer?</button>
        </div>
    )
}

export default Game;