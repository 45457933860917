const NumberPicker = ({values, setter}) => {
    const numbers = [];

    for (let i = 1; i <= 9; i++) {
        numbers.push(<li
            key={i}
            className={values.includes(i) ? "selected" : ""}
            onClick={() => {
                let newValues = [...values];

                if (newValues.includes(i)) {
                    newValues.splice(newValues.indexOf(i), 1);
                } else {
                    newValues.push(i);
                }

                setter(newValues);
            }}
        >{i}</li>)
    }

    return (
        <ul className="picker">{numbers}</ul>
    );
}

export default NumberPicker;