import './layout.scss';

import {useEffect, useState, useRef} from 'react';
import Rewards from "./Components/Rewards";
import Reward from "./Components/Reward";
import RewardModal from "./Components/RewardModal";
import Menu from "./Components/Menu";
import Game from "./Components/Game";
import Lives from "./Components/Lives";

const amountImages = 23;
const streakToEarnLive = 10;
const correctToEarnImage = 4;

function App() {
    const [availableFactors1, setAvailableFactors1] = useState([3, 4, 5, 6, 7, 8, 9]);
    const [availableFactors2, setAvailableFactors2] = useState([3, 4, 5, 6, 7, 8, 9]);
    const [operator, setOperator] = useState('x');
    const [secondsToAnswer, setSecondsToAnswer] = useState(10);
    const [factor1, setFactor1] = useState(null);
    const [factor2, setFactor2] = useState(null);
    const [sumMax, setSumMax] = useState(100);
    const [answer, setAnswer] = useState('');
    const [answerClass, setAnswerClass] = useState('');
    const [countdownStart, setCountdownStart] = useState(null);
    const [countCorrect, setCountCorrect] = useState(0);
    const [countStreak, setCountStreak] = useState(0);
    const [images, setImages] = useState([]);
    const [rewardedImages, setRewardedImages] = useState([]);
    const [mainImage, setMainImage] = useState(null);
    const [lives, setLives] = useState(2);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const menuRef = useRef();
    const [theme, setTheme] = useState('unicorns');
    const [isMobile, setIsMobile] = useState(true);
    const [isRewardShown, setIsRewardShown] = useState(0);

    const getRandom = (options = []) => {
        const min = 0;
        const max = options.length - 1;
        const index = Math.floor(Math.random() * (max - min + 1)) + min;
        return options[index];
    }

    const getPlusSum = (max) => {
        while (true) {
            const f1 = Math.floor(Math.random() * max) + 1;
            const f2 = Math.floor(Math.random() * max) + 1;

            if (f1 + f2 <= max) {
                return [f1, f2];
            }
        }
    }

    const newSum = () => {
        let f1, f2;

        if (operator === 'x') {
            f1 = getRandom(availableFactors1);
            f2 = getRandom(availableFactors2);

            if (Math.random() > 0.5) {
                [f1, f2] = [f2, f1];
            }
        } else {
            const max = sumMax < 4 ? 4 : (sumMax > 1000 ? 1000 : sumMax);

            if (operator === '-') {
                f1 = Math.floor(Math.random() * max) + 1;
                f2 = Math.floor(Math.random() * (f1 - 1)) + 1;
            } else if (operator === '+') {
                [f1, f2] = getPlusSum(max);
            }
        }

        if (factor1 && factor2) {
            // retry if this is the same sum (or reversed same sum) as before
            if ((factor1 === f1 && factor2 === f2) || (factor2 === f1 && factor1 === f2)) {
                return newSum();
            }
        }

        setFactor1(f1);
        setFactor2(f2);
        setCountdownStart(new Date().getTime());
    }

    const getCorrect = () => {
        if (operator === 'x') {
            return factor1 * factor2;
        } else if (operator === '+') {
            return factor1 + factor2;
        } else {
            return factor1 - factor2;
        }
    }

    const checkSum = () => {
        if (!answer) {
            return;
        }

        setCountdownStart(0);
        setAnswer('');

        setTimeout(() => {
            if (Number(answer) === getCorrect()) {
                if (lives < 3) {
                    let newCountStreak = countStreak + 1;

                    if (newCountStreak === streakToEarnLive) {
                        newCountStreak = 0;
                        setLives(lives + 1);
                    }

                    setCountStreak(newCountStreak);
                }

                const msSinceCountdownStart = new Date().getTime() - countdownStart;

                let delayNewSum = false;

                if (msSinceCountdownStart < secondsToAnswer * 1000) {
                    const newCountCorrect = countCorrect + 1;
                    setCountCorrect(newCountCorrect);

                    if ((newCountCorrect + 2) % correctToEarnImage === 0) {
                        preloadImage();
                    }

                    if (newCountCorrect % correctToEarnImage === 0) {
                        if (!images.length) {
                            window.alert('De plaatjes zijn op! Je hebt het spel uitgespeeld! GEFELICITEERD!');
                            return;
                        }

                        const newImages = [...images];
                        const newRewardedImages = [...rewardedImages];
                        const rewardImage = newImages.pop();

                        newRewardedImages.push(rewardImage);
                        setImages(newImages);
                        setMainImage(rewardImage);

                        if (isMobile) {
                            // we delay the newSum, until the reward is closed
                            setIsRewardShown(2);
                            delayNewSum = true;
                        }

                        setRewardedImages(newRewardedImages);
                    }
                }

                setAnswerClass('good');

                if (!delayNewSum) {
                    newSum();
                }
            } else {
                setCountStreak(0);
                setLives(lives - 1);
                setCountCorrect(0);
                setAnswerClass('bad');
            }
        }, 1);
    };

    const reset = () => {
        const images = [];
        for (let i = 1; i <= amountImages; i++) {
            images.push(i + '.jpg');
        }

        // shuffle
        for (let i = images.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [images[i], images[j]] = [images[j], images[i]];
        }

        setImages(images.slice(-16));
        setRewardedImages([]);
        setMainImage(null);
        setCountCorrect(0);
        setLives(2);
        setAnswer('');
        setCountdownStart(0);

        setTimeout(() => {
            newSum();
        }, 1);
    }

    const preloadImage = () => {
        const img = new Image();
        const image = images[images.length - 1];
        img.src = theme + "/" + image;
    };

    useEffect(() => {
        if (answerClass) {
            const timeoutId = setTimeout(() => {
                setAnswerClass('');
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            }
        }
    }, [answerClass]);

    useEffect(() => {
        if (countdownStart) {
            const timeoutId = setTimeout(() => {
                setCountdownStart(null);
            }, secondsToAnswer * 1000);

            return () => {
                clearTimeout(timeoutId);
            }
        }
    }, [countdownStart, secondsToAnswer]);

    // change the current sum if required, on new rules
    useEffect(() => {
        if (factor1 && factor2) {
            if (operator === 'x' && availableFactors1.length && availableFactors2.length) {
                if (!availableFactors1.includes(factor1) || !availableFactors2.includes(factor2)) {
                    newSum();
                }
            } else if (sumMax > 4 && sumMax < 1000) {
                if (operator === '+' && factor1 + factor2 > sumMax) {
                    newSum();
                } else if (operator === '-' && (factor1 > sumMax || factor1 - factor2 < 0 )){
                    newSum();
                }
            }
        }
    }, [operator, sumMax, availableFactors1, availableFactors2, factor1, factor2]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.target.tagName === 'INPUT') {
                return;
            }

            if (/[a-zA-Z0-9]/.test(event.key)) {
                if (event.key === 'Backspace') {
                    setAnswer(answer.slice(0, -1))
                } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(event.key)) {
                    setAnswer(answer + event.key);
                } else if (event.key === 'Enter') {
                    checkSum();
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [answer]);

    useEffect(() => {
        const handleClick = (event) => {
            if (isRewardShown && event.target.className !== 'reward-image') {
                if (isRewardShown === 2) {
                    newSum();
                }

                setIsRewardShown(0);
            }

            if (isMenuVisible && event.target.className !== 'settings-button' && menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setIsMenuVisible(false);
            }
        }

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [isMenuVisible, isRewardShown]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1150);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        reset();
    }, []);

    if (isMobile) {
        return (
           <>
                <div className={`main mobile ${theme}`}>
                    <Menu {...{
                        setIsMenuVisible, isMenuVisible, theme, setTheme, operator, setOperator, availableFactors1,
                        setAvailableFactors1, availableFactors2, setAvailableFactors2, sumMax, setSumMax,
                        secondsToAnswer, setSecondsToAnswer, menuRef, isMobile
                    }}/>
                    <RewardModal {...{mainImage, theme, isRewardShown}}/>
                    <div className="container">
                        <div className="column">
                            <Game {...{
                                lives, factor1, operator, factor2, answerClass, answer, checkSum, countCorrect,
                                countdownStart, secondsToAnswer, getCorrect, reset, isMobile
                            }}/>
                        </div>
                    </div>
                    <Rewards {...{lives, rewardedImages, setMainImage, theme, setIsRewardShown, isRewardShown}}/>
                    <div className={`status-bar ${isRewardShown ? 'reward-shown' : ''}`}>
                        <div className="correct"><p>{countCorrect}</p></div>
                        <Lives lives={lives}/>
                        <div className="right">
                            <img
                                alt="settings"
                                className="settings-button"
                                src="images/settings.png"
                                onClick={() => setIsMenuVisible(!isMenuVisible)}
                            />
                        </div>
                    </div>
                </div>
           </>
        );
    } else {
        return (
            <div className={`main desktop ${theme}`}>
                <Menu {...{
                    setIsMenuVisible, isMenuVisible, theme, setTheme, operator, setOperator, availableFactors1,
                    setAvailableFactors1, availableFactors2, setAvailableFactors2, sumMax, setSumMax,
                    secondsToAnswer, setSecondsToAnswer, menuRef, isMobile
                }}/>
                <img
                    alt="settings"
                    className="settings-button"
                    src="images/settings.png"
                    onClick={() => setIsMenuVisible(!isMenuVisible)}
                />
                <div className="container">
                    <div className="column">
                        <Reward {...{lives, mainImage, theme}}/>
                    </div>
                    <div className="column">
                        <Game {...{
                            lives, factor1, operator, factor2, answerClass, answer, checkSum, countCorrect,
                            countdownStart, secondsToAnswer, getCorrect, reset, isMobile
                        }}/>
                    </div>
                    <div className="column">
                        <Rewards {...{lives, rewardedImages, setMainImage, theme, setIsRewardShown, isRewardShown}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;