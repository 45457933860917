const Reward = ({lives, mainImage, theme}) => {
    if (lives && mainImage) {
        return (
            <div id="reward">
                <img alt="reward" src={theme + "/" + mainImage}/>
            </div>
        );
    }
}

export default Reward;