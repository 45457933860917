const Lives = ({lives}) => {
    const hearts = [];

    for (let i = 1; i <= lives; i++) {
        hearts.push(<div key={'live-' + i} className="live"><span className="fas fa-heart"></span></div>);
    }

    return (
        <div className="lives"> {hearts} </div>
    );
}

export default Lives;