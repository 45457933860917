import Picker from "./Picker";
import NumberPicker from "./NumberPicker";

const Menu = (props) => {
    const {
        isMenuVisible, theme, setTheme, operator, setOperator, availableFactors1, setAvailableFactors1,
        availableFactors2, setAvailableFactors2, sumMax, setSumMax, secondsToAnswer, setSecondsToAnswer,
        menuRef, isMobile
    } = props;

    return (
        <div ref={menuRef}>
            {isMenuVisible && (
                <div id="menu" className={!isMobile ? 'desktop' : undefined}>
                    <div className="row">
                        <div className="label">thema</div>
                        <div>
                            <select defaultValue={theme} onChange={(e) => setTheme(e.target.value)}>
                                <option value="unicorns">unicorns</option>
                                <option value="konijnen">konijnen</option>
                                <option value="dinos">dinos</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">bewerking</div>
                        <div><Picker value={operator} setter={setOperator} options={['+', '-', 'x']}/></div>
                    </div>
                    {operator === 'x' && (
                        <>
                            <div className="row">
                                <div className="label">factor 1</div>
                                <div><NumberPicker values={availableFactors1} setter={setAvailableFactors1} /></div>
                            </div>
                            <div className="row">
                                <div className="label">factor 2</div>
                                <div><NumberPicker values={availableFactors2} setter={setAvailableFactors2} /></div>
                            </div>
                        </>
                    )}
                    {operator === '+' && (
                        <div className="row">
                            <div className="label">max som</div>
                            <div>
                                <input
                                    type="text"
                                    value={sumMax}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setSumMax(value ? Number(value) : value)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {operator === '-' && (
                        <div className="row">
                            <div className="label">max startgetal</div>
                            <div>
                                <input
                                    type="text"
                                    value={sumMax}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setSumMax(value ? Number(value) : value)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="label">seconden</div>
                        <div>
                            <input
                                type="number"
                                value={secondsToAnswer}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setSecondsToAnswer(value ? Number(value) : value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">maker</div>
                        <div>
                            <a className="author" href="https://serenilogic.nl" target="_blank">SereniLogic</a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Menu;