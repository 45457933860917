const Rewards = ({lives, rewardedImages, setMainImage, theme, setIsRewardShown, isRewardShown}) => {
    const rewards = [];

    if (lives) {
        for (const image of rewardedImages) {
            rewards.push(
                <div className="reward-image-wrapper">
                    <img
                        className="reward-image"
                        alt="reward"
                        key={image} src={theme + "/" + image}
                        onClick={() => {
                            setMainImage(image);
                            setIsRewardShown(1);
                        }}
                    />
                </div>
            )
        }
    }

    if (rewards.length) {
        return (
            <div id="rewards" className={isRewardShown ? "reward-shown" : ""}>{rewards}</div>
        );
    }
}

export default Rewards;